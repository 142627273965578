<template>
    <div class="checkTask">
      <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>预约管理</el-breadcrumb-item>
          <el-breadcrumb-item>预约复查</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="tableBox">
          <!-- blue_title -->
          <div class="tableTop">
             <div class="table_l">
                <div class="blueTitle" style="margin-left:12px;">预约复查</div>
                <div v-if="!isSelect" class="new-down-active" @click="completeOrder">完成预约</div>
                <div v-if="isSelect" class="new-down-noactive">完成预约</div>
             </div>
            <div class="table_r">
                <div class="result">
                    <span class="seachText" style="margin-left:14px">预约状态：</span>
                    <el-select v-model="submitOption" @change="selectResult" style="width: 152px !important">
                        <el-option
                            v-for="item in resultOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="search">
                    <el-date-picker
                        v-model="seachDate"
                        value-format="yyyy-MM-dd"
                        type="date"
                        @change="seachBydate"
                        placeholder="选择日期">
                    </el-date-picker>
                    <div class="secbox">搜索</div>
                </div>
                <div class="delete" @click="remove">删除</div>
            </div>
          </div>
           <ReviewTable ref="review"/>
      </div>
    </div>
</template>
<script>

import ReviewTable from '@/components/table/reviewTable.vue'
import {getHospitalList,getRoomList,completeOrder} from '@/api/order.js'
export default {
    beforeRouteLeave(to,from,next){
        from.meta.keepAlive = false;
        next();
    },
    components:{
        ReviewTable,
    },
    data(){
        return{
            seachDate:null,//日期搜索
            submitOption:null,//预约状态
            roomid:'',//当前科室
            hospital:'',
            isSelect:true,
            tableId:null,
           //预约状态选择
           resultOption:[ {
                            label:'全部',
                            value: null
                        }, {
                            label:'已预约',
                            value: '已预约',
                        },
                        {
                            label:'已取消',
                            value:"取消",
                        }
                        ],
        }
    },
    
    mounted(){
        this.getHoList();
    },
    methods:{
        remove(){
             this.$message.error("暂不支持！");
        },
        //预约状态
        selectResult(e){
            //console.log(this.submitOption);
            this.$refs.review.getData(1,10,this.roomid,e,null);
        },
        //按日期搜索
        seachBydate(e){
           this.$refs.review.getData(1,10,this.roomid,null,e);
        },
        //查询医院列表
        getHoList(){
                getHospitalList({
                    //当前该账户下以默认绑定有的
                }).then(res=>{
                    if(res.data.status==200){
                        this.hospitalId = res.data.data.id;
                        this.hospital = res.data.data.orgName;
                        //开始查询科室列表
                        getRoomList({
                        //orgId:this.hospitalId
                    }).then(res=>{
                        if(res.data.status ==200){
                            //console.log(res.data.data.records);
                            var tempL = res.data.data.records;
                            var newL = null;
                            tempL.forEach((item,index)=>{
                                 if(item.deptName.indexOf("复查") != -1 ){
                                    newL = item
                                }
                            })
                            //console.log("bew",newL)
                            this.roomid = newL.id;
                            this.$refs.review.getData(1,10,this.roomid,null,null);
                            }else{
                            this.$message.error(res.data.message);
                            }
                        }).catch(res=>{
                            this.$message.error(res);
                        })
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            //完成预约
            completeOrder(){
                completeOrder({
                    ids:this.tableId
                }).then(res=>{
                    if(res.data.status==200){
                        this.$message({
                            message: '已完成！',
                            type: 'success'
                        });
                        this.$refs.review.getData(1,10,this.roomid,null,null);
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            }
    }
}
</script>
<style scoped lang="stylus">
//change elemen-ui style
//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
//下拉框icon样式
/deep/.el-input__icon {
    line-height: 2.4 !important;
}
/deep/.el-input__inner{
    border-radius: 4px 0 0 4px !important 
}
//搜索框样式--边框颜色
.elSerch>>>.el-input__inner
    border: 1px solid #00cccc !important
    width: 200px
//搜索框样式--高度
/deep/.el-input__inner{
    height: 32px;
  }
//搜索框样式--搜索部分样式
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
  }

.new-down-noactive
  width: 116px 
  height 32px 
  margin-right: 24px
  margin-left: 100px
  background-color:#f4f4f4
  cursor disable
  color: #aeaeae
  line-height: 32px 
  text-align: center
  font-size: 14px
  cursor: not-allowed
.new-down-active
  width: 116px 
  height 32px 
  margin-right: 24px
  background-color:#00CCCC
  cursor pointer
  margin-left: 100px
  color: #fff
  line-height: 32px 
  text-align: center
  font-size: 14px

//component style
.blueTitle
  font-size: 18px 
  color: #353535
  display: flex
  align-items: center 
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:6px
    background-color: #00CCCC


.seachText
    font-size: 14px
    color: #AEAEAE
//normal style
.checkTask
    overflow hidden
    .tableBox
        overflow hidden
        margin-top: 16px
        padding: 20px
        background-color: #FFFFFF
        .tableTop
            display: flex
            margin-top: 10px
            margin-bottom: 30px
            justify-content: space-between
            .table_l
                display: flex
                
            .table_r
                display: flex
                
                .search
                    height: 32px
                    margin-left: 16px
                    display: flex
                    .secbox
                        width: 88px
                        height: 32px  
                        background-color: #00cccc
                        text-align: center
                        line-height: 32px
                        font-size: 14px
                        color: #fff
                .delete
                    width: 64px
                    height: 32px
                    background-color: #FC5555
                    text-align: center
                    line-height:32px
                    color: #fff
                    font-size: 14px
                    margin-left: 16px
                    cursor pointer
                    border-radius: 4px

            
</style>